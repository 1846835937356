@tailwind base;
@tailwind components;
@tailwind utilities;

#new-content-request-div {
    display: flex;
    flex-direction: row;
    /* justify-content: center !important; */
    /* align-items: center !important; */
}
