#creator-dash {
    height: 100%;
    width: 100%;
}

#my-licensed-media-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.half-width {
    width: 50%;
}